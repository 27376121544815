import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import React from "react";
import {
  AsyncTypeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import { Helmet } from "react-helmet";
import { ContentInfo } from "./Content";
import { ScatterPlot } from "./Plot";
import MultiRangeSlider from "multi-range-slider-react";
import _ from "lodash";
import { ActionLink } from "./ActionLink";
import { subscribe, unsubscribe, publish } from "./events";
import moment from "moment-mini";
import ReactGA from "react-ga4";
ReactGA.initialize("G-Q5EFVM4BM7");

let defaultDates = {
  min: "1970-07-09",
  max: "1998-03-23",
};

const getPathname = () => {
  if (window.location.pathname.length > 1) {
    let pathname = window.location.pathname.substring(1);
    if (pathname.indexOf("week/") > -1) {
      window.location = "/" + pathname.replace("week/", "show/");
    }
    return pathname;
  } else if (window.location.search.indexOf("q=") > -1) {
    return window.location.search;
  }
};

const renderTypeAheadChoices = (results, menuProps, props) => {
  if (!results) {
    return;
  }
  let index = 0;

  // Group the results by the "type" key in each option.
  const regions = _.groupBy(
    _.filter(results, (r) => r.label),
    "type"
  );
  const items = _.sortBy(Object.keys(regions), (region) => {
    const sortOrder = ["song", "artist", "album", "date"];
    return sortOrder.indexOf(region);
  }).map((region) => {
    return (
      <React.Fragment key={region}>
        {index !== 0 && <Menu.Divider />}
        <Menu.Header>{region}</Menu.Header>
        {regions[region].map((option) => {
          const item = (
            <MenuItem key={option.label} option={option} position={index}>
              <Highlighter search={props.text}>{option.label}</Highlighter>
            </MenuItem>
          );
          index += 1;
          return item;
        })}
      </React.Fragment>
    );
  });

  return <Menu {...menuProps}>{items}</Menu>;
};

function App() {
  const SERVER_BASE =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (["443", "80"].includes(window.location.port)
      ? ""
      : ":" + window.location.port.replace("3000", "3001")) +
    "/api/";

  const DEFAULT_PAGE_TITLE = "Top 40 Trends";
  const DEFAULT_META_DESCRIPTION =
    "All the songs, artists, and shows from American Top 40 and Caseys' Top 40";

  const detectMobile = () => {
    return window.innerWidth < 640 ? true : false;
  };

  const doSetMobile = () => {
    setTimeout(() => {
      setScreen({ isMobile: detectMobile() });
    }, 250);
  };

  const doSetSelectedFromLocation = () => {
    let currentPath = getPathname();
    setPath(currentPath);
  };

  const handleLinkClicked = (e) => {
    let payload = e.detail;
    if (payload) {
      window.history.pushState({}, "", payload);
      // Remove the first / from the payload
      payload = payload.substring(1);
      setPath(payload);
      window.scroll(0, 0);
    }
  };

  const handleTypeAheadSearch = (query) => {
    setTypeAheadLoading(true);
    fetch(`${SERVER_BASE}filter?q=${query}`)
      .then((resp) => resp.json())
      .then((data) => {
        setOptions(data);
        setTypeAheadLoading(false);
      });
  };

  const typeAheadSelect = (vals) => {
    let newUrl = "";
    if (vals.length === 0) {
      newUrl = "";
      setData(null);
      setSelected([]);
    } else if (vals.length === 1) {
      newUrl = `${vals[0].url}`;
    } else {
      newUrl = `?q=${_.map(vals, "url").join("+").replaceAll("/", ":")}`;
    }
    if (newUrl !== path) {
      window.history.pushState({}, "", "/" + newUrl);
      setSummary({});
      setPath(newUrl);
    }
  };

  function setDates(min, max) {
    let myMin = min ? min : defaultDates.min;
    let myMax = max ? max : defaultDates.max;
    myMin = myMin < defaultDates.min ? defaultDates.min : myMin;
    myMax = myMax > defaultDates.max ? defaultDates.max : myMax;
    if (myMax === myMin) {
      myMin = moment(myMin).add(-1, "days").format("YYYY-MM-DD");
      myMax = moment(myMax).add(1, "days").format("YYYY-MM-DD");
    }
    if (myMax !== graphDates.max || myMin !== graphDates.min) {
      setGraphDates({
        min: myMin,
        max: myMax,
      });
    }
  }

  const updateHighlighted = (slug) => {
    // Find the song in the data
    let song = _.find(data.data, { slug });
    if (slug) {
      setSummary({ ...summary, highlighted: song });
    }
  };

  function dateToNumber(dateString) {
    return moment(dateString).diff(moment(defaultDates.min), "days");
  }

  function numberToDate(number) {
    return moment(defaultDates.min).add(number, "days").format("YYYY-MM-DD");
  }

  const handleDateSliderChange = (e) => {
    let minDate = numberToDate(e.minValue);
    let maxDate = numberToDate(e.maxValue);
    if (minDate !== graphDates.min || maxDate !== graphDates.max) {
      setDates(numberToDate(e.minValue), numberToDate(e.maxValue));
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMM Do 'YY");
  };

  const shortFormatDate = (dateString) => {
    return moment(dateString).format("MMM 'YY");
  };

  const getDateLabels = () => {
    // Find 5 dates in between the min and max
    let min = moment(dataDates.min);
    let max = moment(dataDates.max);
    let diff = max.diff(min, "days");
    let step = Math.floor(diff / 5);
    let labels = [shortFormatDate(min)];
    for (let i = 0; i < 5; i++) {
      labels.push(shortFormatDate(min.add(step, "days")));
    }
    return _.uniq(labels);
  };

  const [summary, setSummary] = React.useState({});
  const [path, setPath] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [random, setRandom] = React.useState(null);
  const [graphData, setGraphData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [typeAheadLoading, setTypeAheadLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [screen, setScreen] = React.useState({ isMobile: detectMobile() });
  const [showChart, setShowChart] = React.useState(true);
  const [showExtras, setShowExtras] = React.useState(false);
  const [showSpecial, setShowSpecial] = React.useState(false);
  const [dataDates, setDataDates] = React.useState(defaultDates);
  const [graphDates, setGraphDates] = React.useState(defaultDates);
  const [chartHeight, setChartHeight] = React.useState(400);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [links, setLinks] = React.useState(null);
  const [isinit, setIsInit] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState(DEFAULT_PAGE_TITLE);
  const [metaDescription, setMetaDescription] = React.useState(
    DEFAULT_META_DESCRIPTION
  );
  const [footerFetchStarted, setFooterFetchStarted] = React.useState(false);
  const [showWeekSongs, setShowWeekSongs] = React.useState(false);

  const filterData = () => {
    if (!data) return;
    let newData = data.data;
    if (data.options.length === 1 && data.options[0].type === "show") {
      newData = [];
      for (var i = 0; i < data.data.length; i++) {
        let song = data.data[i];
        let newSong = {
          ...song,
        };
        let showData = _.filter(song.data, { x: data.options[0].date });
        let nonShowData = _.filter(
          song.data,
          (d) => d.x !== data.options[0].date
        );
        let showSong = showData[0];
        if (showData.length > 1) {
          let duplicate = showData.pop();
          showSong = showData[0];
          newData.push({
            ...song,
            id: `${duplicate.rank ? "#" + duplicate.rank : duplicate.label}  ${
              newSong.id
            }`,
            data: [duplicate],
          });
        }
        newSong = {
          ...song,
          id: `${showSong.rank ? "#" + showSong.rank : showSong.label}  ${
            newSong.id
          }`,
          data: _.sortBy([...nonShowData, ...showData], (d) => d.x),
        };
        newData.push(newSong);
      }
    }
    // Filter based on the criteria
    let filteredData = [];
    for (i = 0; i < newData.length; i++) {
      let newSong = newData[i];
      const fd = _.filter(newSong.data, (d) => {
        let doShow = false;
        if (data.options[0].type === "show" && d.x === data.options[0].date) {
          doShow = true;
        }
        if (showChart) {
          if (d.rank !== "" && !d.isSpecial) {
            doShow = true;
          }
        }
        if (showExtras) {
          if (d.rank === "") {
            doShow = true;
          }
        }
        if (showSpecial) {
          if (d.isSpecial) {
            doShow = true;
          }
        }
        return doShow;
      });
      if (fd.length > 0) {
        filteredData.push({ ...newSong, data: fd });
      }
      setShowWeekSongs(false);
      if (data.options.length === 1) {
        setPageTitle(data.options[0].label);
        if (data.options[0].type === "show") {
          setShowWeekSongs(true);
          filteredData = _.sortBy(filteredData, (d) => {
            let week = _.find(d.data, { x: data.options[0].date });
            return week?.y;
          });
        } else if (data.options[0].type === "artist") {
          filteredData = _.sortBy(filteredData, (d) => d.data[0]?.x);
        }
      }
    }

    // Get the min and max dates from the data
    let weeks = _.map(_.flatten(_.map(filteredData, "data")), "x");
    let minDate = _.min(weeks);
    let maxDate = _.max(weeks);
    let restrictDates = false;
    if (graphDates.min !== dataDates.min || graphDates.max !== dataDates.max) {
      restrictDates = true;
    }
    setDataDates({ min: minDate, max: maxDate });
    if (restrictDates) {
      let dateFilteredData = [];
      for (i = 0; i < filteredData.length; i++) {
        let newSong = filteredData[i];
        const fd = _.filter(newSong.data, (d) => {
          return d.x >= graphDates.min && d.x <= graphDates.max;
        });
        if (fd.length > 0) {
          dateFilteredData.push({ ...newSong, data: fd });
        }
      }
      filteredData = dateFilteredData;
    } else {
      if (data.options[0].type === "show") {
        setDates(data.options[0].date, data.options[0].date);
      } else {
        setDates(minDate, maxDate);
      }
    }
    setGraphData(filteredData);
  };

  React.useEffect(() => {
    if (!isinit) {
      return;
    }
    const fetchDataForPosts = async () => {
      if (loading) return;
      try {
        setLoading(true);
        const response = await fetch(SERVER_BASE + path);
        if (!response.ok) {
          if (response.status === 404) {
            setError("Page not found");
            return;
          } else {
            throw new Error(`HTTP error: Status ${response.status}`);
          }
        }
        let postsData = await response.json();
        setGraphDates(defaultDates);
        setDataDates(defaultDates);
        setData(postsData);
        if (!firstLoad) {
          setRandom(null);
        } else {
          setFirstLoad(false);
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    const fetchRandom = async () => {
      if (random) return;
      try {
        setLoading(true);
        const response = await fetch(SERVER_BASE + "random");
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let randomData = await response.json();
        setSelected([]);
        setSummary({});
        setRandom(randomData);
        publish("linkClicked", `/song/${randomData.display.data[0].slug}`);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    if (path) {
      fetchDataForPosts();
    } else {
      if (firstLoad) {
        fetchRandom();
      }
    }
    let gaPath = "/" + path ? path : "";
    if (window.location.hostname.indexOf("localhost") === -1) {
      ReactGA.send({
        hitType: "pageview",
        page: gaPath,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  React.useEffect(() => {
    if (data) {
      setSelected(data.options);
    }
    if (data?.data) {
      if (data.options.length === 1) {
        setPageTitle(data.options[0].label);
        if (data.options[0].type === "show") {
          setSummary({ selected: { ...data, type: "show" } });
          setMetaDescription(
            `See all the songs on show ${data.options[0].label}`
          );
        } else if (data.options[0].type === "artist") {
          setSummary({ selected: { songs: data.data, type: "artist" } });
          setMetaDescription(
            `See all the songs by ${data.options[0].label} on American Top 40 and Casey's Top 40`
          );
        } else if (data.options[0].type === "song") {
          let song = data.data[0];
          setSummary({ selected: { ...song, type: "song" } });
          setMetaDescription(
            `See how often ${song.title} by ${song?.artist} was on American Top 40 and Casey's Top 40`
          );
        }
      } else {
        setPageTitle(DEFAULT_PAGE_TITLE);
        setMetaDescription(DEFAULT_META_DESCRIPTION);
      }
    } else {
      setGraphData([]);
      setDataDates(defaultDates);
      setGraphDates(defaultDates);
    }
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExtras, showSpecial, showChart, graphDates]);

  React.useEffect(() => {
    let chartHeight = 400;
    if (graphData?.length > 0) {
      chartHeight =
        (graphData.length * 16 < 400 ? 400 : graphData.length * 16) + 24;
    }
    setChartHeight(chartHeight);
  }, [graphData]);

  React.useEffect(() => {
    const fetchFooterData = async () => {
      if (footerFetchStarted) return;
      setFooterFetchStarted(true);
      try {
        const response = await fetch(SERVER_BASE + "/links");
        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
        let postsData = await response.json();
        setLinks(postsData);
      } catch (err) {
        setLinks(null);
      }
    };
    window.addEventListener("orientationchange", doSetMobile);
    window.addEventListener("popstate", doSetSelectedFromLocation);
    subscribe("linkClicked", handleLinkClicked);
    if (isinit) {
      return;
    }
    let currentPath = getPathname();
    setIsInit(true);
    setPath(currentPath);
    fetchFooterData();
    return () => {
      window.removeEventListener("orientationchange", doSetMobile);
      window.removeEventListener("popstate", doSetSelectedFromLocation);
      unsubscribe("linkClicked", handleLinkClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>
          {pageTitle === DEFAULT_PAGE_TITLE
            ? pageTitle
            : pageTitle + " | " + DEFAULT_PAGE_TITLE}
        </title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <a href="/" style={{ textDecoration: "none", color: "black" }}>
        <h1>Top 40 Trends</h1>
      </a>
      <p>{DEFAULT_META_DESCRIPTION}</p>
      <div>
        <AsyncTypeahead
          multiple
          className="Search"
          id="searchBar"
          isLoading={typeAheadLoading}
          labelKey="label"
          minLength={1}
          onChange={typeAheadSelect}
          onSearch={handleTypeAheadSearch}
          options={options}
          placeholder="Type to search for an artist, song or week..."
          renderMenu={renderTypeAheadChoices}
          selected={selected}
          delay={50}
        />
      </div>
      {summary?.selected ? (
        <h2 className="mt-3">{data.options[0].label}</h2>
      ) : (
        ""
      )}
      {error ? (
        <div className="alert alert-warning mt-2">
          Error fetching data from the server
          <br />
          <br />
          {error}
        </div>
      ) : (
        ""
      )}
      {random && data ? (
        <p className="mt-3">
          Welcome to Top 40 trends. To get you started, we have selected a
          random top 10 song. Search for a song, artist or week in the search
          box to see your favorites!
        </p>
      ) : null}
      {data ? (
        <div className="Graph" style={{ height: chartHeight }}>
          <ScatterPlot
            data={graphData}
            screen={screen}
            onHighlight={updateHighlighted}
            dates={graphDates}
          />
        </div>
      ) : (
        ""
      )}

      {data ? (
        <>
          <div className="row mt-2">
            <div className="col-12">
              <MultiRangeSlider
                labels={getDateLabels()}
                minValue={dateToNumber(graphDates.min)}
                maxValue={dateToNumber(graphDates.max)}
                step={7}
                min={dateToNumber(dataDates.min)}
                max={dateToNumber(dataDates.max)}
                onInput={(e) => {
                  handleDateSliderChange(e);
                }}
                minCaption={formatDate(graphDates.min)}
                maxCaption={formatDate(graphDates.max)}
                label={true}
                ruler={true}
                style={{
                  border: "none",
                  boxShadow: "none",
                  padding: "15px 15px",
                  marginRight: screen.isMobile ? "0px" : "230px",
                }}
                barLeftColor="white"
                barInnerColor="blue"
                barRightColor="white"
                thumbLeftColor="blue"
                thumbRightColor="blue"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 pt-2">
              <input
                type="checkbox"
                className="form-check-input"
                checked={showChart}
                onChange={() => {
                  setShowChart(!showChart);
                }}
              />{" "}
              <label className="form-check-label">Charting</label>
            </div>
            <div className="col-12 col-md-4 pt-2">
              <input
                type="checkbox"
                className="form-check-input"
                checked={showExtras}
                onChange={() => {
                  setShowExtras(!showExtras);
                }}
              />{" "}
              <label className="form-check-label">Extras</label>
            </div>
            <div className="col-12 col-md-4 pt-2">
              <input
                type="checkbox"
                className="form-check-input"
                checked={showSpecial}
                onChange={() => {
                  setShowSpecial(!showSpecial);
                }}
              />{" "}
              <label className="form-check-label">Specials</label>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <ContentInfo content={summary} />
      <div className="footer">
        <div className="row g-0">
          <div className="col-sm">
            <p>
              {data?.pagination?.prev ? (
                <ActionLink
                  href={`/${data.pagination.prev.url}`}
                  linkText={`« ${data.pagination.prev.label}`}
                />
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="col-sm">
            <p className="text-end">
              {data?.pagination?.next ? (
                <ActionLink
                  href={`/${data.pagination.next.url}`}
                  linkText={`${data.pagination.next.label}  »`}
                />
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
        {showWeekSongs ? (
          <div>
            <h3>All Songs on Countdown</h3>
            <ul>
              {_.clone(graphData)
                .reverse()
                .map((s) => {
                  let song = _.clone(s);
                  return (
                    <li key={song.slug}>
                      <ActionLink
                        href={`/song/${song.slug}`}
                        linkText={song.id}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          ""
        )}
        {random ? (
          <div className="mt-2">
            <hr />
            <h4>Remember these?</h4>
            <p>
              Here are some additional random songs, artists, and shows to get
              you started!
            </p>
            <div className="row">
              <div className="col-12 col-md-4">
                <h5>Songs</h5>
                <ul>
                  {random.songs.map((song) => {
                    return (
                      <li key={song.url}>
                        <ActionLink
                          href={`/${song.url}`}
                          linkText={song.label}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <h5>Artists</h5>
                <ul>
                  {random.artists.map((artist) => {
                    return (
                      <li key={`${artist.url}`}>
                        <ActionLink
                          href={`/${artist.url}`}
                          linkText={artist.label}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <h5>Shows</h5>
                <ul>
                  {random.shows.map((show) => {
                    return (
                      <li key={`${show.url}`}>
                        <ActionLink
                          href={`/${show.url}`}
                          linkText={show.label}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="p-5  pb-1 text-center">
          <p className="p-0 p-md-5 pb-md-1">
            <small>
              This is a personal project for a bit of fun. Data obtained from
              the MP3s of{" "}
              <a
                href="http://www.charismusicgroup.com/Calendar.htm"
                target="_blank"
                rel="noreferrer"
              >
                American Top 40 (70s & 80s)
              </a>{" "}
              and{" "}
              <a
                href="http://www.charismusicgroup.com/CT40_calendar.htm"
                target="_blank"
                rel="noreferrer"
              >
                Caseys Top 40
              </a>{" "}
              purchased from{" "}
              <a
                href="http://www.charismusicgroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                Charis Music Group
              </a>
              .
              <br />
              <br />
              Feedback? Shoot me an email at{" "}
              <a href="mailto:&#116;&#111;&#112;&#052;&#048;&#064;&#103;&#097;&#109;&#101;&#115;&#104;&#097;&#112;&#101;&#046;&#099;&#111;&#109;">
                &#116;&#111;&#112;&#052;&#048;&#064;&#103;&#097;&#109;&#101;&#115;&#104;&#097;&#112;&#101;&#046;&#099;&#111;&#109;
              </a>
            </small>
          </p>
        </div>
        {links ? (
          <div className="row p-3" style={{ fontSize: 14 }}>
            <div className="col-12 col-md-4 text-center">
              Song #1:{" "}
              <ActionLink
                href={`/${links.song.url}`}
                linkText={links.song.label}
              />
            </div>
            <div className="col-12 col-md-4 text-center">
              Artist #1 (A-Z):{" "}
              <ActionLink
                href={`/${links?.artist.url}`}
                linkText={links?.artist.label}
              />
            </div>
            <div className="col-12 col-md-4 text-center">
              Show #1:{" "}
              <ActionLink
                href={`/${links.show.url}`}
                linkText={links.show.label}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default App;
